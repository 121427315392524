.Logo {
  display: flex;
  height: 3rem;
  align-items: center;
  background-color: white;
  cursor: pointer;
  border-right: 1px solid var(--chakra-colors-chakra-border-color);
  border-bottom: 1px solid var(--chakra-colors-chakra-border-color);
}

.Logo-Text {
  z-index: 100;
  display: block;
  width: 3rem;
  font-size: 1.5rem;
  text-align: center;
  user-select: none;
  transition: all 0.5s ease 0s !important;
  color: black;
}

.Logo-Block {
  display: block;
  position: absolute;
  width: 3rem;
  height: 80%;
  background-color: black;
  transition: all 0.5s ease 0s !important;
  left: 0px;
}
